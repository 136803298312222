import { useState, useEffect } from "react"
import { getTowns } from "../api/requests";

const useTowns = () => {
  const [towns, setTowns] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true)
    getTowns({'filters[active][$eq]': true})
      .then(towns => {
        if (towns) setTowns(towns);
        else setTowns(null);
      })
      .catch(() => setTowns(null))
      .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [])
  
  return {
    towns,
    loading
  }
}

export default useTowns;
