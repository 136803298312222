import React, {useContext} from "react";
import ArticleTemplate from "../shared/ArticleTemplate";
import { useParams } from "react-router-dom";

import Loading from "../shared/Loading";
import {useNavigate} from "react-router";
import {LanguageContext} from "../../hooks/wrappers/LanguageWrapper";
import useArticle from "../../hooks/useArticle";


const NewsArticle = ({parentPath}) => {
  let navigate = useNavigate();
  const { lang } = useContext(LanguageContext);
  const { articleId } = useParams();
  const {
    article,
    isLoading
  } = useArticle(articleId, { populate: '*' })

  if(!isLoading && !article) navigate(-1)
  if(isLoading) return <Loading />

  const { localizations, title, text, banner, attached_file } = article || {}
  return (
    <ArticleTemplate
      title={localizations?.[lang]?.title || title}
      text={localizations?.[lang]?.text || text} textAsHtml
      banner={banner?.url}
      file={attached_file?.url}
      backRoute={parentPath}
    />
  )
}

export default NewsArticle;