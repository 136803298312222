import React, {useContext, useState} from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { Container, Row, Col } from "reactstrap"
import { Rating } from 'react-simple-star-rating'
import _ from "lodash";

import {
  MdOutlineSentimentDissatisfied,
  MdOutlineSentimentNeutral,
  MdOutlineSentimentSatisfied,
  MdOutlineSentimentVeryDissatisfied,
  MdOutlineSentimentVerySatisfied
} from 'react-icons/md'

import useTownSurvey from "../../hooks/useSurvey";

import { LanguageContext } from "../../hooks/wrappers/LanguageWrapper";
import { TownContext } from "../../hooks/wrappers/TownWrapper";

import SectionHeader from "../shared/SectionHeader";
import BackButton from "../shared/BackButton";

import placeholderBg from "../../media/placeholder.jpg";
import Loading from "../shared/Loading";
import useSurveyResponse from "../../hooks/useSurveyResponse";

const customWidth ='calc(12vw + 30px)'


const faces = [
  { icon: <MdOutlineSentimentVeryDissatisfied size={customWidth} /> },
  { icon: <MdOutlineSentimentDissatisfied size={customWidth} /> },
  { icon: <MdOutlineSentimentNeutral size={customWidth} /> },
  { icon: <MdOutlineSentimentSatisfied size={customWidth} /> },
  { icon: <MdOutlineSentimentVerySatisfied size={customWidth} /> }
]

const Surveys = ({ parentPath }) => {
  let navigate = useNavigate();
  const { town } = useContext(TownContext)
  const { t, lang } = useContext(LanguageContext);
  const [sent, setSent] = useState(false);

  const {
    townSurvey,
    isLoading: isLoadingSurvey
  } = useTownSurvey(town?.id)

  const [currIndex, setCurrIndex] = useState(0);
  const {
    answers,
    setAnswers,
    submit,
    isLoading: isSubmitingSurvey
  } = useSurveyResponse()

  const handleAnswer = (index, answer) => {
    setAnswers(answers => {
      const answersCopy = _.cloneDeep(answers)
      answersCopy[index] = {
        score: answer / 20,
        question: townSurvey[index].id
      };
      return answersCopy
    })
  }

  if(isLoadingSurvey || isSubmitingSurvey) return <Loading />
  if(!isLoadingSurvey && !townSurvey?.length) navigate("/" + town?.identifier);

  const currQuestion = townSurvey?.[currIndex]
  const currAnswer = answers?.[currIndex]

  if(sent) return (
    <div className="d-flex flex-column h-100 w-100 px-5">
      <div className=" py-3 w-100">
        <SectionHeader banner={town?.banner?.url || placeholderBg} title={t("survey")} style={{ fontSize: 22 }} />
      </div>
      <div  className="flex-center text-success w-100 h-50 text-center" style={{ fontSize:100 }}>
        {t("thanksSubmit")}
      </div>
    </div>
  )

  return (
    <div className="d-flex flex-column h-100 w-100 px-5">
      <BackButton route={parentPath} />
      <div className=" py-3 w-100">
        <SectionHeader banner={town?.banner?.url || placeholderBg} title={t("survey")} style={{ fontSize: 22 }} />
      </div>
      <Container className="fluid pb-5" style={{ flex: 1 }}>

        <Row className="pt-3 justify-content-center">
          <div style={{ fontSize: 45 }} className="text-center text-primary">
            {currQuestion?.localizations?.[lang]?.question || currQuestion?.question}
          </div>
        </Row>
        <Row className="justify-content-center my-5">
          <Col className="flex-center mw-100">
            <Rating
              emptyClassName="flex-wrap d-flex justify-content-center"
              fillColorArray={['#f43229', '#ffca45', '#85f450', '#76d5a9', '#51a2fe']}
              customIcons={faces}
              initialValue={0}
              ratingValue={currAnswer?.score ? currAnswer?.score * 20 : null}
              onClick={a => handleAnswer(currIndex, a)}
              emptyIcon
            />
          </Col>
        </Row>

        <Row className="justify-content-between px-5" style={{ fontSize: 25 }}>
          {currIndex !== 0 &&
            <Button
              className="w-auto bg-primary px-5"
              disabled={currIndex === 0}
              onClick={() => (currIndex < 0) ?
                setCurrIndex(townSurvey.length - 1) :
                setCurrIndex(currIndex - 1)
              }
            >
              {t("previous")}
            </Button>
          }
          {(currIndex >= (townSurvey.length-1)) ?
            <Button
              className="w-auto bg-primary px-5 ms-auto"
              disabled={!currAnswer?.score}
              onClick={() =>
                submit().then(() => {
                  setSent(true)
                  setTimeout(() => {
                    navigate("/" + town?.identifier);
                  }, 3000);
                })
              }
            >
              {t("submit")}
            </Button>
            :
            <Button
              className="w-auto bg-primary px-5 ms-auto"
              disabled={!currAnswer?.score}
              onClick={() => (currIndex === townSurvey.length-1) ?
                setCurrIndex(-1) :
                setCurrIndex(currIndex + 1)
              }
            >
              {t("next")}
            </Button>
          }
        </Row>

      </Container>
    </div>
  );
}

export default Surveys;
