import ca from "./ca.json";
import de from "./de.json";
import en from "./en.json";
import es from "./es.json";
import it from "./it.json";

export const dictionaryList = { ca, de, en, es, it };

export const languageOptions = {
  ca: "Català",
  de: "Deutsch",
  en: "English",
  es: "Español",
  it: "Italiano"
};