import { useState, useEffect } from "react"
import { getArticle } from "../api/requests";

const useArticle = (id, params) => {
  const [article, setArticle] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true)
    getArticle(id, params)
      .then(article => {
        if (article) setArticle(article);
        else setArticle(null);
      })
      .catch(() => setArticle(null))
      .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [id])
  
  return {
    article,
    loading
  }
}

export default useArticle;
