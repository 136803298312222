import axios from 'axios';
import moment from "moment";

import formatStrapi4Response from "./utils/formatStrapi4Response";
const request = axios.create({ baseURL: process.env.REACT_APP_API_URL });


const townFields = [
  'history',
  'crest',
  'heritage',
  'local_celebrations',
  'culture',
  'tourism',
  'health',
  'statistics',
  'banner',
  'coordinates'
]

const cleanResponse = ({data}) => formatStrapi4Response(data)



export const getTowns = params => request.get("/towns", {params}).then(cleanResponse);
export const getTown = (identifier, params) =>
  request.get("/towns", { params: { 
    'filters[identifier][$eq]': identifier,
    ...(townFields.reduce((r, f) => ({...r, [`populate[${f}][populate]`]: '*'}), {})),
  }}).then(cleanResponse).then(r => r[0])
export const getTownById = (id, params) =>
  request.get(`/towns/${id}`, { params }).then(cleanResponse)

export const getDefaultSurvey = params =>
  request.get(`/default-survey`, { params }).then(cleanResponse)

export const getTownScreensavers = townId => request.get(`/screensavers`, { params: {
    'filters[$and][0][$or][0][town][id][$eq]': townId,
    'filters[$and][0][$or][1][town][id][$null]': true,
    'filters[$and][1][$or][0][expiration_date][$null]': true,
    'filters[$and][1][$or][1][expiration_date][$gte]': moment().toISOString(),
    populate: '*'
  }}).then(cleanResponse);

export const getArticles = params => request.get("/articles", {params}).then(cleanResponse);
export const getArticle = (articleId, params) => request.get(`/articles/${articleId}`, {params}).then(cleanResponse);

// export const getVideo = (videoId, params) => request.get(`/videos/${videoId}`, {params});


export const createAnswer = body => request.post(`/answers`, {data: body}).then(cleanResponse)
export const createSurveyResponse = body => request.post(`/survey-responses`, {data: body}).then(cleanResponse)