import React, { useEffect, createContext } from 'react';

export const IsInfopointContext = createContext();

const IsInfopointWrapper = ({ isInfopoint, children }) => {

  var styles = `
  * {
  cursor: none !important;
}
`




  useEffect(() => {
    if (isInfopoint) {
      var styleSheet = document.createElement("style")
      styleSheet.innerText = styles
      document.head.appendChild(styleSheet)
    }
    

    // eslint-disable-next-line
  }, [isInfopoint]);

  return (
    <IsInfopointContext.Provider value={isInfopoint}>
      {children}
    </IsInfopointContext.Provider>
  );
};

export default IsInfopointWrapper