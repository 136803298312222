import React from "react";

import { Container } from "reactstrap";

const Loading = () => {
  return (
    <Container className="flex-center h-100">
      <h1>Loading...</h1>
    </Container>
  );
}

export default Loading;