import React, { useContext } from "react";
import { Col, Container, Row } from "reactstrap"
import { useNavigate } from "react-router-dom";

import { LanguageContext } from "../hooks/wrappers/LanguageWrapper";
import { homeSubroutes } from "../Router";

const Home = () => {
  let navigate = useNavigate();
  const { t } = useContext(LanguageContext);

  return (
    <Container fluid className="py-4 px-3 px-md-5 h-100">
      <Row className="text-primary text-uppercase h-100 text-center flex-center">
        {homeSubroutes.map(({icon, text, uri}, i) => 
          <Col key={i} className="px-4 px-md-5" xs='6' sm='4' md='6' xl='4'>
            <img 
              src={icon}
              width="100%"
              style={{
                cursor: 'pointer',
                maxWidth: 250,
                maxHeight: '30vh'
              }}
              className="mb-2 mb-lg-4"
              onClick={() => navigate(`./${uri}`)}
              alt=""
            />
            <div className="sectionBtnText mb-5" style={{}}>
              <div onClick={() => navigate(uri)} style={{cursor:"pointer"}}>{t(text)}</div>
            </div>
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default Home;