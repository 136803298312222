import React from "react";


const NotFound = () => {
  return (
    <div className="w-100 h-100 flex-center bree">
      <div style={{ color: "grey", fontSize: 200 }}>404</div>
    </div>
  );
}

export default NotFound;