import React, { createContext, useState } from 'react';
import { dictionaryList, languageOptions } from '../../lang';



export const LanguageContext = createContext({
  lang: 'en',
  dictionary: dictionaryList.en
});

const LanguageWrapper = ({ children }) => {
  const defaultLanguage = window.localStorage.getItem('hi-services-lang');
  const [lang, setLang] = useState(defaultLanguage || 'en');

  const t = ( tid ) => {
    return dictionaryList[lang][tid] || tid;
  };

  const changeLanguage = selected => {
    const newLanguage = languageOptions[selected] ? selected : 'en'
    setLang(newLanguage);
    window.localStorage.setItem('hi-services-lang', newLanguage);
  }

  const provider = {
    lang,
    dictionary: dictionaryList[lang],
    changeLanguage,
    t
  };

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageWrapper