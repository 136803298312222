import React from "react";

import { Row } from "reactstrap"

import placeholder from "../../media/placeholder.jpg"


const circleDiam = 'calc(6vw + 6vh + 50px)';
const minCircleDiam = '130px';
const maxCircleDiam = undefined;


const SectionHeader = ({title, banner, style, className}) => {
  return (
    <Row
      className="p-3 p-md-4 p-lg-5 border border-1 border-primary"
      style={{ ...styles.header, backgroundImage: `url(${banner || placeholder})`}}
    >
      <div
        className={
          "headerText flex-center border border-4 border-primary rounded-circle " +
          "text-primary text-uppercase text-center bg-white " +
          className
        }
        style={{ ...styles.circle, ...style }}
      >
        {title}
      </div>
    </Row>
  );
}

const styles = {
  header:{
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: '0.25rem'
  },
  circle: {
    height: circleDiam,
    width: circleDiam,
    minHeight: minCircleDiam,
    minWidth: minCircleDiam,
    maxHeight: maxCircleDiam,
    maxWidth: maxCircleDiam,
    fontWeight: 500,
    lineHeight: 1.1,
  }
}

export default SectionHeader;