import React, {useEffect, useState, useContext} from "react";
import { Col, Container, Row } from "reactstrap"

import { LanguageContext } from "../hooks/wrappers/LanguageWrapper";
import { TownContext } from "../hooks/wrappers/TownWrapper";

import axios from "axios";

const maxTemp = 50
const minTemp = -10


const getTempColor = (temp) => {
  const tempRange = maxTemp - minTemp
  const redVal = 255 / tempRange * Math.min(Math.max(temp - minTemp, 0), 255)
  const blueVal = 255 / tempRange * Math.min(Math.max(maxTemp - temp, 0), 255)
  return `rgb(${redVal}, 0, ${blueVal})`
}
  
const WeatherBar = () => {
  const { t } = useContext(LanguageContext);
  const { town } = useContext(TownContext)
  const [weather, setWeather] = useState(null);
  const { latitude: lat, longitude: lon } = town?.coordinates || {}


  const apiCall = () => {
    if(!town) return setWeather(null)
    if(process.env.NODE_ENV === 'production')
      axios.get(
        `https://api.openweathermap.org/data/2.5/onecall`,
        {params:{
          lat, lon,
          units: 'metric',
          exclude: 'daily,minutely,hourly,alerts' ,
          appid: process.env.REACT_APP_WEATHER_API_KEY
        }}
      )
      .then(({data}) => {
        const {humidity, temp, uvi} = data.current
        setWeather({
          humidity,
          temp: temp.toFixed(1),
          uvi
        })
      })
      .catch(console.log)
    else
      setWeather({
        humidity: 99,
        temp: 99.9,
        uvi: 99
      })
  }

  useEffect(() => {
    apiCall()
    const interval = setInterval(apiCall, 1800000); //1800 secs, that's 30mins
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [town]);


  return (
    <Container
      className="py-1 py-lg-2 px-5 bg-light text-center border-primary text-primary text-uppercase"
      style={{ border: 5, borderStyle: 'solid none', fontWeight: "bold" }}
      fluid
    >
      {weather ?
        <Row className="justify-content-around">

          <Col xs="auto" className="flex-center flex-column">
            <div className="titlesWB">{t("temperature")}</div>
            <span style={{color: getTempColor(weather.temp)}} className="valuesWB">
              {weather.temp}ºC
            </span>
          </Col>

          <Col xs="auto" className="flex-center flex-column">
            <div className="titlesWB">{t("uv index")}</div>
            <div className="d-flex">
              <div style={{color: "#007dbc"}} className="valuesWB">{weather.uvi}</div>
            </div>
          </Col>

          <Col xs="auto" className="flex-center flex-column">
            <div className="titlesWB">{t("humidity")}</div>
            <span style={{color: "#183668"}} className="valuesWB">{weather.humidity}%</span>
          </Col>

        </Row>
        :
        <Row className="p-4" />
      }
    </Container>
  );
}

export default WeatherBar;


