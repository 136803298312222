import React, { useContext } from "react";
import { Col, Container, Row } from "reactstrap";

import { LanguageContext } from "../../hooks/wrappers/LanguageWrapper";
import { ModalContext } from "../../hooks/wrappers/ModalWrapper";
import { TownContext } from "../../hooks/wrappers/TownWrapper";

import SectionHeader from "./SectionHeader";
import BackButton from "./BackButton";
import Loading from "./Loading";


const PosterTemplate = ({ images, backRoute, banner, title }) => {
  const { t } = useContext(LanguageContext);
  const { openModal } = useContext(ModalContext);

  return (
    <Container fluid className="px-4 px-md-5 py-3 py-lg-4 h-100">
      <BackButton route={backRoute} />
      <SectionHeader banner={banner} title={t(title)}/>
      <Row>
        {images.length ?
          images.map((img, i) =>
            <Col key={i} xs="12" className="py-2">
              <img
                src={img}
                className="w-100 mx-auto"
                style={{
                  objectFit: 'contain',
                  cursor: "pointer",
                  maxHeight: '50vh'
                }}
                onClick={() => openModal(<BigImage img={img} />)}
                alt=""
              />
            </Col>
          )
          :
          <Col xs={12} className="text-center">
            {/* TODO */}
          </Col>
        }
      </Row>
    </Container>
  );
}

const BigImage = ({img}) =>
  <img
    src={img} className="mx-auto"
    style={{width:"95%", maxHeight:"95%", objectFit:"contain"}}
    alt=""
  />

const SectionPoster = ({ sectionName, backRoute }) => {
  const { town, isLoading } = useContext(TownContext)

  if(isLoading) return <Loading />

  const section = town[sectionName]
  const banner = section?.banner?.url
  const images = section?.images ? section.images.map(i => i.url) : []
  return <PosterTemplate title={sectionName} banner={banner} images={images} backRoute={backRoute} />
}


export { SectionPoster }
export default PosterTemplate;