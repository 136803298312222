import React, {useContext} from "react"
import { useNavigate, useParams } from "react-router-dom";

import backArrow from "../../media/arrow_back.svg"
import {IsInfopointContext} from "../../hooks/wrappers/IsInfopointWrapper";

const BackButton = ({route = "/"}) => {
  const isInfopoint = useContext(IsInfopointContext)
  const {town} = useParams();
  let navigate = useNavigate();
  return isInfopoint &&
    <img
      src={backArrow}
      className="position-fixed backButton"
      onClick={() => navigate("/" + town + route)}
      alt=""
    />

}

export default BackButton;
