import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import Loading from "./shared/Loading";
import { townCookieName } from "../hooks/wrappers/TownWrapper";


const LoadTownCookie = () => {
  const [town, setTown] = useState()

  useEffect(() => {
    setTown(localStorage.getItem(townCookieName) || null);
  }, []);

  return !town ?
      town === undefined ?
        <Loading />
      :
        <Navigate replace to="/" />
    :
      <Navigate replace to={`/${town}`} />
      // <Navigate replace to="/" />
}

export default LoadTownCookie;