import React/*, { useContext }*/ from "react";
import { Col, Container, Row } from "reactstrap";
import { useNavigate, Navigate } from "react-router-dom";
// import { VideoFolderContext } from "../hooks/wrappers/VideoFolderWrapper";
// import { LanguageContext } from "../hooks/wrappers/LanguageWrapper";

import useTowns from "../hooks/useTowns";
import Loading from "./shared/Loading";

const Towns = () => {
  // const { t } = useContext(LanguageContext);
  // const { setVideoFolderPath, setAreVideosInLocal, areVideosInLocal } = useContext(VideoFolderContext);
  const { towns, loading } = useTowns();
  // const setFolderPath = (e) => {
  //   setVideoFolderPath(Object.values(e.target.files));
  //   setAreVideosInLocal(true);
  // };
  let navigate = useNavigate();

  return loading ?
    <Loading />
    : towns === null ?
      <Navigate replace to="/404" />
      :
      <Container className="h-100" fluid>
        {/*{!areVideosInLocal && */}
        {/*  <Row className="justify-content-center px-3 pt-3">*/}
        {/*    <label className="btn btn-default btn-file border border-4 border-danger rounded w-100 bg-light text-danger" style={{ fontSize: 50, cursor: 'pointer' }}>*/}
        {/*    {t("videoSelectText")} <input type="file" webkitdirectory="true" directory="" onChange={setFolderPath} style={{ display:"none" }} />*/}
        {/*    </label>*/}
        {/*  </Row> */}
        {/*}*/}
        <Row className="p-2 text-primary text-uppercase">
          {towns.map(({name, identifier}, i) =>
            <Col
              key={i}
              xs={12} md={6} xl={4}
              onClick={() => navigate(`/${identifier}`)}
              style={{ fontSize: 50, cursor: 'pointer' }}
              className="p-2 h-100"
            >
              <div className="bg-light text-center py-5">
                {name}
              </div>
            </Col>
          )}
        </Row>
      </Container>
}

export default Towns;