import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import Celebrations from "./pages/home/municipality/Celebrations";
import Crest from "./pages/home/municipality/Crest.js";
import Culture from "./pages/home/municipality/Culture.js";
import Health from "./pages/home/Health";
import Heritage from "./pages/home/municipality/Heritage.js";
import History from "./pages/home/municipality/History.js";
import Home from "./pages/Home.js";
import Municipality from "./pages/home/Municipality.js";
import News from "./pages/home/News";
import Objective from "./pages/home/Objective";
import NewsArticle from "./pages/home/NewsArticle"

import Statistics from "./pages/home/municipality/Statistics.js";
import Surveys from "./pages/home/Surveys";
import Tourism from "./pages/home/Tourism";



// ---- Icons ----
//  home
import munIcon from "./media/buttonIcons/municipio.svg"
import actIcon from "./media/buttonIcons/actualidad.svg"
import encIcon from "./media/buttonIcons/encuestas.svg"
import turIcon from "./media/buttonIcons/turismo.svg"
import salIcon from "./media/buttonIcons/salud.svg"
import sosIcon from "./media/buttonIcons/sostenible.svg"


//  municipality
import hisIcon from "./media/buttonIcons/history.svg"
import creIcon from "./media/buttonIcons/crest.svg"
import celIcon from "./media/buttonIcons/local_celebrations.svg"
import herIcon from "./media/buttonIcons/heritage.svg"
import culIcon from "./media/buttonIcons/culture_and_tradition.svg"
import staIcon from "./media/buttonIcons/statistics.svg"




const municipalitySubroutes = [
  {icon: hisIcon, uri: "history", text: "history", component: History },
  {icon: creIcon, uri: "crest", text: "crest", component: Crest },
  {icon: herIcon, uri: "heritage", text: "heritage", component: Heritage },
  {icon: celIcon, uri: "local-celebrations", text: "local_celebrations", component: Celebrations },
  {icon: culIcon, uri: "culture-and-traditions", text: "culture", component: Culture },
  {icon: staIcon, uri: "statistics", text: "statistics", component: Statistics }
];

const newsSubroutes = [
  {uri: ":articleId", component: NewsArticle}
]

const homeSubroutes = [
  {icon: munIcon, uri: "municipality", text: "municipality", component: Municipality, subRoutes: municipalitySubroutes},
  {icon: actIcon, uri: "news", text: "news", component: News, subRoutes: newsSubroutes},
  {icon: encIcon, uri: "forms", text: "surveys", component: Surveys},
  {icon: turIcon, uri: "tourism", text: "tourism", component: Tourism},
  {icon: salIcon, uri: "health", text: "health", component: Health},
  // {icon: sosIcon, uri: "sustainable", text: "objective", component: Objective}
  {icon: sosIcon, uri: "", text: "objective", component: Objective}
];



const routes = [
  { uri: '', component: Home, subRoutes: homeSubroutes}
];


const mountRoutes = (routes, prevPath = "") => 
  routes.map(({uri, component: Component, subRoutes}, i) => {
    const absPath = `${prevPath}/${uri}`.replace('//', '/')
    return (subRoutes && subRoutes.length > 0) ?
      <Route path={absPath} key={i}>
        <Route index element={<Component parentPath={prevPath} />}/>
        {mountRoutes(subRoutes, absPath)}
      </Route>
    :
      <Route key={i} path={absPath} element={<Component parentPath={prevPath} />} />
  })

const Router = () => <Routes>
  {mountRoutes(routes)} 
  <Route path="*" element={<Navigate replace to="/" />}/>
</Routes>


export { routes, homeSubroutes, municipalitySubroutes }
export default Router;