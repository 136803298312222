import { useContext } from "react"
// import { ModalContext } from "../../hooks/wrappers/ModalWrapper"
import SectionHeader from "../shared/SectionHeader"
import { Container } from "reactstrap"
// import { Col, Container, Row } from "reactstrap"
// import ReactPlayer from "react-player"
// import defaultImg from "../../media/background_video_example.jpg"
import placeholderBg from "../../media/placeholder.jpg";
import { LanguageContext } from "../../hooks/wrappers/LanguageWrapper";
// import { ScreenSaverContext } from "../../hooks/wrappers/ScreenSaverWrapper"
// import { VideoFolderContext } from "../../hooks/wrappers/VideoFolderWrapper"


import BackButton from "../shared/BackButton";
import {TownContext} from "../../hooks/wrappers/TownWrapper";



const Objective = ({ parentPath }) => {
  // const { setVideoFolderPath, videoFolderPath, setAreVideosInLocal, areVideosInLocal } = useContext(VideoFolderContext);
  // .log(videoFolderPath)
  const { t } = useContext(LanguageContext);
  const { town } = useContext(TownContext)
  // const { setEnabled } = useContext(ScreenSaverContext);
  // const { openModal } = useContext(ModalContext);

  // const videos = Object.values(videoFolderPath.reduce(
  //   (r, file) => {
  //       if(!r[file.name]) r[file.name] = {}
  //       //Stores the file in the videos array with the type of file as key
  //       r[file.name][file.type.split("/")[0]] = file
  //       return r
  //   },
  //   {}
  // ))


  return (
    <Container fluid className="px-4 px-md-5 py-3 py-lg-4">
      <SectionHeader banner={town?.banner?.url || placeholderBg} title={t("objective")} />
      {/*<Row className="py-3 py-lg-4">*/}
      {/*  {videos.map(({ video, image }, i) =>*/}
      {/*    <Col key={i} xs="12" className="mb-4">*/}
      {/*      <div className="position-relative w-100">*/}
      {/*        <img src={image?.webkitRelativePath || defaultImg} className="w-100" alt="" />*/}
      {/*        <i*/}
      {/*          className="fa-solid fa-play position-absolute text-white"*/}
      {/*          style={{ zIndex: 1, top: "50%", left: "50%", transform: "translate(-50%, -50%)", fontSize: 100, cursor: "pointer" }}*/}
      {/*          onClick={() => {*/}
      {/*            openModal(*/}
      {/*              <ReactPlayer*/}
      {/*                url={"/home/subzerus/Desktop/videos/video_example_2.mp4"}*/}
      {/*                controls*/}
      {/*                playing={true}*/}
      {/*                className="w-100 h-auto mx-auto px-3"*/}
      {/*              />,*/}
      {/*              () => setEnabled(true)*/}
      {/*            );*/}
      {/*            setEnabled(false);*/}
      {/*          }}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </Col>*/}
      {/*  )}*/}
      {/*</Row>*/}
      <BackButton route={parentPath} />
    </Container>
  );
}

export default Objective;