import { useEffect, useState } from "react";

import {getDefaultSurvey, getTownById} from "../api/requests";


const useTownSurvey = townId => {
  const [survey, setSurvey] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    refresh().catch(() => null)
    // eslint-disable-next-line
  }, [townId])

  const refresh = async () => {
    if(!townId) return;
    setLoading(true)
    return loadTownSurvey(townId)
      .then(questions => {
        setSurvey(questions)
        return questions
      })
      .finally(() => {
        setLoading(false)
      })
  }


  return {
    townSurvey: survey,
    refresh,
    isLoading
  }
}

export const loadTownSurvey = async townId => {
  try {
    const town = await getTownById(townId, { populate: ['questions.question.localizations'] })
    let survey = town.questions.map(q => q.question)
    if(town?.show_default_questions) {
      try {
        const defaultSurvey = await getDefaultSurvey({ populate: ['questions.question.localizations'] })
        survey = defaultSurvey.questions.map(q => q.question).concat(survey)
      } catch (e) {}
    }
    return survey
  } catch (e) {
    const message = (e?.message || "Error al cargar la encuesta")
    throw new Error(message)
  }
}


export default useTownSurvey;