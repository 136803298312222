import { useState, useEffect } from "react"
import { getTown } from "../api/requests";

const useTown = identifier => {
  const [town, setTown] = useState();
  const [isLoading, setIsLoading] = useState(true);


  const refresh = () => {
    setIsLoading(true)
    getTown(identifier)
      .then(newTown => setTown(newTown || null))
      .catch(() => setTown(null))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    refresh()
    // eslint-disable-next-line
  }, [identifier])

  return {
    town,
    refresh,
    isLoading
  }
}

export default useTown;
