
import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";

import LanguageWrapper from "./hooks/wrappers/LanguageWrapper";
import ModalWrapper from "./hooks/wrappers/ModalWrapper";
import TownWrapper from "./hooks/wrappers/TownWrapper"

import Header from "./components/Header"
import Router from "./Router";
import NotFound from "./pages/NotFound"
import Towns from "./pages/Towns";
import LoadTownCookie from "./pages/LoadTownCookie";
import Footer from "./components/Footer";
import ScreenSaverWrapper from "./hooks/wrappers/ScreenSaverWrapper";
import IsInfopointWrapper from "./hooks/wrappers/IsInfopointWrapper"
// import VideoFolderWrapper from "./hooks/wrappers/VideoFolderWrapper";


const App = () => {
  const hasBasename = window.location.pathname.split('/')[1] === 'infopoint'
  return (
    <BrowserRouter basename={hasBasename ? 'infopoint' : ''}>
      <LanguageWrapper>
        <IsInfopointWrapper isInfopoint={hasBasename}>
          {/*<VideoFolderWrapper>*/}
            <ModalWrapper>
              <div className="vh-100 d-flex flex-column bree overflow-hidden">
                <Routes>
                  <Route
                    path=":town/*"
                    element={
                      <TownWrapper>
                        <ScreenSaverWrapper>
                          <Body>
                            <Router />
                          </Body>
                        </ScreenSaverWrapper>
                      </TownWrapper>
                    }
                  />
                  <Route path="" element={<Body><Towns /></Body>} />
                  <Route path="404" element={<Body><NotFound /></Body>} />
                  <Route path="town/cookie" element={<Body><LoadTownCookie /></Body>} />
                </Routes>
              </div>
            </ModalWrapper>
          {/*</VideoFolderWrapper>*/}
        </IsInfopointWrapper>
      </LanguageWrapper>
    </BrowserRouter>
  );
}

const Body = ({ children }) => (
  <React.Fragment>
    <Header />
    <div className="position-relative" style={{ flex: 1, overflowX: 'hidden', overflowY: 'scroll' }}>
      {children}
    </div>
    <Footer />
  </React.Fragment>
)


export default App;
