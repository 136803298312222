import React, { useContext } from "react";
import { Container, Row } from "reactstrap"

import { IsInfopointContext } from "../../hooks/wrappers/IsInfopointWrapper";
import { LanguageContext } from "../../hooks/wrappers/LanguageWrapper";
import { TownContext } from "../../hooks/wrappers/TownWrapper";

import BackButton from "./BackButton";
import Loading from "./Loading";

import placeholder from "../../media/placeholder.jpg";


const ArticleTemplate = ({title, text, banner, file, backRoute, textAsHtml}) => {
  console.log({title, text, banner, file, backRoute})
  const { t } = useContext(LanguageContext);
  const isInfopoint = useContext(IsInfopointContext)
  return (
    <Container fluid className="px-3 px-md-4 py-3 py-lg-4 h-100">
      <BackButton route={backRoute}/>
      <img
        src={banner || placeholder}
        className="mb-3 w-100 border border-1 border-primary w-100"
        style={styles.banner}
        alt=""
      />
      <Row className="mt-3 text-secondary px-4 mb-5">
        <h1 className="fw-bold text-uppercase mb-3" style={{ fontSize: 28 }}>
          {!file || isInfopoint ?
            t(title)
          :
            <a href={file} target="_blank" rel="noreferrer">
              {t(title)} <i className="fa-solid fa-link"/>
            </a>
          }
        </h1>
        {textAsHtml ?
          <div className="text-start w-100 p-0" style={{ fontSize: 22, lineHeight: 1.1 }} dangerouslySetInnerHTML={{__html: text}}/>
        :
          <div className="text-start w-100 p-0" style={{ fontSize: 22, lineHeight: 1.1 }}>
            {text}
          </div>
        }
      </Row>
    </Container>
  );
}

const SectionArticle = ({ sectionName, backRoute }) => {
  const { town, isLoading } = useContext(TownContext)
  const { lang } = useContext(LanguageContext)

  if(isLoading) return <Loading />

  const section = town[sectionName]
  const banner = section?.banner?.url
  let text = section?.localizations?.[lang]?.text
  if(text) text = text.split('\n').map((t, i) => <p key={i}>{t}</p>)
  const attachedFile = section?.attached_file?.url
  return <ArticleTemplate title={sectionName} text={text} banner={banner} file={attachedFile} backRoute={backRoute} />
}


const styles = {
  banner: {
    borderRadius: '0.25rem',
    maxHeight: '40vh',
    height: 400,
    objectFit: "cover"
  }
}


export { SectionArticle }
export default ArticleTemplate;