import React, {useState, createContext, useEffect, useRef, useContext, useMemo} from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { IsInfopointContext } from './IsInfopointWrapper';
import { TownContext } from "./TownWrapper";
import useEventListener from '../useEventListener';
import useScreensavers from "../useScreensavers";


export const ScreenSaverContext = createContext(null);

const ScreenSaverWrapper = ({ children }) => {
  const isInfopoint = useContext(IsInfopointContext);
  const { town } = useParams();
  let navigate = useNavigate();

  const [showScreenSaver, setShowScreenSaver] = useState(false);
  const [enabled, setEnabled] = useState(true);

  const enableScreenSaver = enabled && (isInfopoint || process.env.NODE_ENV === 'development');


  let myTimeout = useRef();
  const startTimeOut = () => {
    clearTimeout(myTimeout.current)
    if (enableScreenSaver)
      myTimeout.current = setTimeout(() => {
        navigate("/" + town);
        setShowScreenSaver(true);
      }, 300000); // 5min
  }


  // eslint-disable-next-line
  useEffect(startTimeOut, [enableScreenSaver])

  useEventListener("click", () => {
    startTimeOut()
    setShowScreenSaver(false);
  })

  const isShowingScreenSaver = showScreenSaver && enableScreenSaver
  return (
    <ScreenSaverContext.Provider value={{ setEnabled, enabled }}>
      <ScreenSaver isShowingScreenSaver={isShowingScreenSaver}/>
      {children}
    </ScreenSaverContext.Provider>
  );
};




const ScreenSaver = ({isShowingScreenSaver}) => {
  const { town } = useContext(TownContext)
  const [index, setIndex] = useState(0)
  const { screensavers } = useScreensavers(town?.id)

  const images = useMemo(() =>
    screensavers
      .sort((ss1, ss2) => (ss1.town && !ss2.town) ? -1 : 1)
      .map(ss => ss?.image?.url)
    // eslint-disable-next-line
  , [screensavers, isShowingScreenSaver])

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(index =>  index < images.length - 1 ? index + 1 : 0)
    }, 15000);
    return () => clearInterval(interval);
  }, [images]);

  if(!isShowingScreenSaver || !images.length) return null

  return (
    <div className='position-fixed w-100 h-100' style={{ zIndex: 1000 }}>
      <div
        className="d-flex flex-column justify-content-center w-100 h-100 position-relative"
        style={{ zIndex: 1000, backgroundColor: 'white', top: "50%", left: "50%", transform: "translate(-50%, -50%" }}
      >
        {images.map((img, i) =>
          <img key={i} src={img}
            className={`h-100 w-100 mt-xl-n5 imageCrossfade position-absolute ${i !== index && 'opacity-0'}`}
            style={{ left: '50%', transform: 'translateX(-50%)', objectFit: "contain" }}
            alt="" />
        )}
      </div>
    </div>
  );

}

export default ScreenSaverWrapper;

