import React, { useContext } from "react";

import {LanguageContext} from "../../hooks/wrappers/LanguageWrapper";

import caIcon from "../../media/flags/cat.svg"
// import deIcon from "../../media/flags/de.svg"
// import enIcon from "../../media/flags/en.svg"
import esIcon from "../../media/flags/es.svg"
// import itIcon from "../../media/flags/it.svg"

const flags = [
  {icon: caIcon, lang: "ca"},
  // {icon: deIcon, lang: "de"},
  // {icon: enIcon, lang: "en"},
  {icon: esIcon, lang: "es"},
  // {icon: itIcon, lang: "it"}
]


const LangFlags = ({ className = "", style }) => {
  const {changeLanguage } = useContext(LanguageContext);

  return (
    <div className={"d-flex h-100 " + className} style={style}>
      {flags.map(({icon, lang}, i) =>
        <div key={i} className="h-100" style={{cursor: "pointer"}} onClick={() => changeLanguage(lang)}>
          <img src={icon} className="h-100" style={{minHeight: 30}} alt="" />
        </div>
      )}
    </div>
  );
}

export default LangFlags;
