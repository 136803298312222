import React, { useContext } from "react";
import { useNavigate } from "react-router";
import { Col, Container, Row, Card, CardImg, CardBody, CardTitle } from "reactstrap"

import { LanguageContext } from "../../hooks/wrappers/LanguageWrapper";
import {TownContext} from "../../hooks/wrappers/TownWrapper";
import useArticles from "../../hooks/useArticles";

import SectionHeader from "../shared/SectionHeader";
import BackButton from "../shared/BackButton";

import placeholderBg from "../../media/placeholder.jpg";
import moment from "moment";
import Loading from "../shared/Loading";


const News = ({ parentPath }) => {
  let navigate = useNavigate();
  const { town } = useContext(TownContext)
  const { t, lang } = useContext(LanguageContext);

  const {
    articles = [],
    isLoading
  } = useArticles({
    populate: '*',
    'filters[town][id]': town?.id,
    'filters[$or][0][publishing_date][$lte]': moment().toISOString(),
    'filters[$or][1][publishing_date][$null]': true
  })

  if(isLoading) return <Loading />
  return (
    <Container fluid className="px-4 px-md-4 py-3 py-lg-4">
      <BackButton route={parentPath} />
      <SectionHeader banner={town?.banner?.url || placeholderBg} title={t("news")} />
      <Row className="p-0 mx-n4">
        {articles.map(({ id, title, text, localizations, banner }, i) =>
          <Col key={i} xs="12" md="6" xl="4" className="py-3">
            <Card className="p-0" style={{cursor:"pointer"}} onClick={() => navigate(`${id}`)}>
              <CardImg
                variant="top"
                src={banner?.url}
                style={{ height: 300, backgroundRepeat: "no-repeat", objectFit: "cover", backgroundPosition: "center"}}
                alt=""
              />
              <CardBody>
                <CardTitle style={{ fontSize: 18 }}>{localizations?.[lang]?.title || title}</CardTitle>
                {/*<CardText*/}
                {/*  style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}*/}
                {/*  dangerouslySetInnerHTML={{ __html: localizations?.[lang]?.text || text }}*/}
                {/*/>*/}
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );

}


export default News;