import { useState, useEffect } from "react"
import { getArticles } from "../api/requests";

const useArticles = params => {
  const [articles, setArticles] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true)
    getArticles(params)
      .then(articles => {
        if(articles) setArticles(articles);
        else setArticles(null);
      })
      .catch(() => setArticles(null))
      .finally(() => setIsLoading(false))
    // eslint-disable-next-line
  }, [])
  
  return {
    articles,
    isLoading
  }
}

export default useArticles;
