import React, { useState, createContext, useRef } from 'react';

export const ModalContext = createContext(null);

const ModalWrapper = ({ children }) => {

  let onClose = useRef();

  const [content, setContent] = useState(null);

  const openModal = (content, callback = () => null) => {
    onClose.current = callback;
    setContent(content);
  }

  const closeModal = () => {
    onClose.current();
    setContent(null);
  }


  return (
    <ModalContext.Provider value={{openModal}}>
      {content && <Modal closeModal={closeModal} children={content}/> }
      {children}
    </ModalContext.Provider>
  );
};

export default ModalWrapper;

const Modal = ({closeModal, children}) => {
  return (
    <div 
      className="d-flex flex-column justify-content-center w-100 h-100 position-fixed" 
      style={{zIndex:100, backgroundColor:"rgba(0,0,0,0.5", top:"50%", left:"50%", transform:"translate(-50%, -50%"}}
      onClick={closeModal} >
      {children}
    </div>
  );
}

