import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Collapse } from "reactstrap"
import { useNavigate, useParams } from "react-router-dom";
import Hamburger from 'hamburger-react'

import moment from "moment"
import 'moment/locale/es'
import 'moment/locale/ca'
import 'moment/locale/de'
import 'moment/locale/it'

import { LanguageContext } from "../hooks/wrappers/LanguageWrapper";
import { TownContext } from "../hooks/wrappers/TownWrapper";

import WeatherBar from "./WeatherBar";

import circle from "../media/headerCircle.svg"
import logo from "../media/logo.svg"




const Header = () => {
  const navigate = useNavigate();
  const { town: townId } = useParams();
  const { lang } = useContext(LanguageContext);
  const { town } = useContext(TownContext);
  const [isOpen, setOpen] = useState(false)
  const [time, setTime] = useState(moment())
  moment.locale(lang)

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment())
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Container fluid className="shadow-sm text-primary text-uppercase">
      <Row className="justify-content-between align-items-center px-2 px-lg-4 overflow-hidden" style={{ fontWeight: 600 }}>
        <Col
          className="h-100"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/" + townId)}
        >
          <img src={logo} style={styles.logo} alt="" />
        </Col>

        <Col xs="auto" className="text-center position-relative d-none d-lg-block" style={{ fontSize: 45 }}>
          <div>{town?.name}</div>
          <div style={{ fontSize: 23 }}>
            <b>{time.format("dddd")}, </b>
            {time.format("D MMMM YYYY")}
          </div>
          <div className="fw-bold">{time.format("kk:mm")}</div>

          <img src={circle} style={styles.circle} alt=""/>
        </Col>

        <Col xs="auto" className="h-100 d-lg-none flex-center"  style={{ cursor: "pointer" }}>
          <div className="me-3 mb-n1" style={{ lineHeight: 1, fontSize: 'calc(1vw + 1.5vh)' }}>{town?.name}</div>
          <Hamburger toggled={isOpen} toggle={setOpen} size={27} aria-label="menu"/>
        </Col>
      </Row>

      <Row className="p-0">
        <Collapse isOpen={isOpen} className="w-100 d-lg-none p-0">
          <WeatherBar />
        </Collapse>
      </Row>

      <Row className="p-0 d-none d-lg-flex">
        <WeatherBar />
      </Row>

    </Container>
  )
}

const styles = {
  logo: {
    objectFit: "contain",
    maxHeight: '18vh',
    minHeight: 80,
    maxWidth: '95%',
    marginTop: -5
  },
  circle: {
    width: 400,
    maxWidth: '40vw',
    minWidth: '25vw',
    zIndex: -1,
    position: "absolute",
    top: '50%', left: '60%',
    transform: 'translate(-50%, -50%)'
  }
}

export default Header;