import React, { createContext, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import useTown from "../useTown";

export const TownContext = createContext({});

export const townCookieName = 'INFOPOINT_TOWN'

const TownWrapper = ({ children }) => {
  let navigate = useNavigate();
  const { town: identifier } = useParams();
  const {
    town,
    refresh,
    isLoading
  } = useTown(identifier)

  useEffect(() => {
    if(town) localStorage.setItem(townCookieName, town?.identifier)
  }, [town])


  useEffect(() => {
    if(!isLoading && (!town || !town.active)) navigate('/')
    else {

      const timeout = setTimeout(() => {
        window.location.reload()
      }, 2880000); // 8h

      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line
  }, [town, isLoading])

  return (
    <TownContext.Provider value={{town, refresh, isLoading}}>
      {children}
    </TownContext.Provider>
  )
}
export default TownWrapper;
