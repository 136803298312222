import {useContext, useState} from "react";

import { createAnswer, createSurveyResponse } from "../api/requests";
import { TownContext } from "./wrappers/TownWrapper";


const useSurveyResponse = () => {
  const { town } = useContext(TownContext)
  const [answers, setAnswers] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const submit = () => {
    if (!town || !answers.length || isLoading) return;
    setLoading(true)
    return submitSurvey(answers, town.id)
      .finally(() =>
        setLoading(false)
      )
  }

  return {
    answers,
    setAnswers,
    submit,
    isLoading
  }
}

export const submitSurvey = async (answers, townId) => {
  try {
    const surveyResponse = await createSurveyResponse({ town: townId })
    return await Promise.all(answers.map(answer => {
      return createAnswer({...answer, survey_response: surveyResponse.id })
    }))
  } catch (e) {
    const message = (e?.message || "Error al enviar el formulario")
    throw new Error(message)
  }
}


export default useSurveyResponse;