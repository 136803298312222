import { useState, useEffect } from "react"
import { getTownScreensavers } from "../api/requests";

const useScreensavers = (townId) => {
  const [screensavers, setScreensavers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(!townId) return
    setLoading(true)
    getTownScreensavers(townId)
      .then(screensavers => {
        if (screensavers) setScreensavers(screensavers);
        else setScreensavers(null);
      })
      .catch(() => setScreensavers(null))
      .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [townId])
  
  return {
    screensavers,
    loading
  }
}

export default useScreensavers;
