import React, { useContext } from "react";

import { Col, Container, Row } from "reactstrap"
import { useNavigate } from "react-router";

import { municipalitySubroutes } from "../../Router";
import BackButton from "../shared/BackButton";
import {LanguageContext} from "../../hooks/wrappers/LanguageWrapper";
import SectionHeader from "../shared/SectionHeader";
import {TownContext} from "../../hooks/wrappers/TownWrapper";
import placeholderBg from "../../media/placeholder.jpg";

const Municipality = ({parentPath}) => {
  const { t } = useContext(LanguageContext);
  const { town } = useContext(TownContext)
  let navigate = useNavigate();

  return (
    <Container fluid className="px-4 px-md-5 py-3 py-lg-4 h-100 text-uppercase">
      <BackButton route={parentPath}/>
      <SectionHeader banner={town?.banner?.url || placeholderBg} title={town?.name} />
      <Row className="py-3">
        {municipalitySubroutes.map(({icon, text, uri}, i) =>
          <Col
            key={i} xs="12" md="6"
            className="d-flex align-items-center py-2 py-md-4 pb-lg-5"
            style={{cursor: 'pointer'}}
            onClick={() => navigate(uri)}
          >
            <img src={icon} style={styles.circle}  alt=""/>
            <div
              style={styles.sectionName}
              className="d-flex align-items-center text-start bg-primary text-white pe-3 sectionBtnText"
              onClick={() => navigate(uri)}
            >
              {t(text)}
            </div>
          </Col>
        )}
      </Row>
    </Container>
  );
}

const styles = {
  circle: {
    cursor: 'pointer',
    marginRight: -35,
    width: '35%',
    maxWidth: 150,
    zIndex: 1
  },
  sectionName: {
    flex: 1,
    fontWeight: 400,
    borderRadius: 10,
    height: '70%',
    padding: '10px 10px 10px 50px'
  }
}


export default Municipality;


