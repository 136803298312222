import React, { useContext } from "react";
import { Col, Container, Row } from "reactstrap"

import {LanguageContext} from "../hooks/wrappers/LanguageWrapper";
import accessibility from "../media/buttonIcons/accessibilitat.svg"
import touchIcon from "../media/buttonIcons/seleccio.svg"

import LangFlags from "./shared/LangFlags";
import {IsInfopointContext} from "../hooks/wrappers/IsInfopointWrapper";


const Footer = () => {
  const { t } = useContext(LanguageContext);
  const isInfopoint = useContext(IsInfopointContext)
  
  return (
    <Container fluid className="shadow-sm bg-primary px-2 px-lg-5 py-3">
      <Row className="h-100" style={{ minHeight: 50, maxHeight: '12vh' }}>

        {!!isInfopoint && <>
          <Col xs="1" className="d-none d-lg-block h-100">
            <img src={accessibility} height="100%" alt=""/>
          </Col>
          <Col className="text-center d-none d-lg-block h-100">
            <img src={touchIcon} style={{height: '4vh', minHeight: 20}} alt=""/>
            <div style={{fontWeight: 300, fontSize: 17, letterSpacing: 1.1}} className="mt-2 text-uppercase text-white">
              {t("touchscreen")}
            </div>
          </Col>
        </>}


        <Col className={`d-flex justify-content-end ms-auto h-100 ${isInfopoint ? 'pt-lg-4' : ''}`}>
          <LangFlags/>
        </Col>

      </Row>
    </Container>
  );
}

export default Footer;
